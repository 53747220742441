<template>
  <div>
    <div v-if="isOrderLoaded" class="card card-custom gutter-b">
      <div class="card-body p-0">
        <div class="row justify-content-center py-8 px-8 py-md-27 px-md-0">
          <div class="col-md-10">
            <div
              class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row"
            >
              <h1 class="display-4 font-weight-boldest mb-10">ORDER DETAILS</h1>
              <div class="d-flex flex-column align-items-md-end px-0">
                <!--begin::Logo-->
                <a href="#" class="mb-5">
                  <img
                    src="/metronic/theme/html/demo1/dist/assets/media/logos/logo-dark.png"
                    alt=""
                  />
                </a>
                <!--end::Logo-->
                <span class="d-flex flex-column align-items-md-end opacity-70">
                  <span>Jovana Popovića Lipovca 11</span>
                  <span>81000 Podgorica</span>
                </span>
              </div>
            </div>
            <div class="border-bottom w-100"></div>
            <div class="d-flex justify-content-between pt-6">
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">ORDER DATE</span>
                <span class="opacity-70">{{
                  order.createdAt | formatDateTime
                }}</span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">ORDER NO.</span>
                <span class="opacity-70">{{ order.orderId }}</span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">DELIVERED TO.</span>
                <span v-if="isCompanyLoaded" class="opacity-70"
                  >{{ company.name }} <br />
                  {{ company.address }}
                </span>
              </div>
              <div class="d-flex flex-column flex-root">
                <span class="font-weight-bolder mb-2">USER</span>
                <span v-if="isUserLoaded" class="opacity-70"
                  >{{ user.personalInfo.name }} {{ user.personalInfo.surname }}
                  <br />
                  {{ user.email }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
          <div class="col-md-10">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="pl-0 font-weight-bold text-muted text-uppercase">
                      Ordered Items
                    </th>
                    <th
                      class="text-right font-weight-bold text-muted text-uppercase"
                    >
                      Qty
                    </th>
                    <th
                      class="text-right font-weight-bold text-muted text-uppercase"
                    >
                      Unit Price
                    </th>
                    <th
                      class="text-right pr-0 font-weight-bold text-muted text-uppercase"
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(order, key) in order.items"
                    :key="key"
                    class="font-weight-boldest"
                  >
                    <td class="border-0 pl-0 pt-7 d-flex align-items-center">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                        <img
                          v-if="
                            order.product.featuredImage.hasOwnProperty('src')
                          "
                          :src="
                            appPath +
                              '/media/cache/resolve/50x50/' +
                              order.product.featuredImage.src
                          "
                          class="h-75 align-self-end"
                          alt=""
                        />
                      </div>
                      <!--end::Symbol-->
                      <div>
                        <div>{{ order.product.name }}</div>
                        <div>SKU: {{ order.product.sku }}</div>
                      </div>
                    </td>
                    <td class="text-right pt-7 align-middle">
                      {{ order.quantity }}
                    </td>
                    <td class="text-right pt-7 align-middle">
                      {{ parseFloat(order.product.price) | currencyFormat }} €
                    </td>
                    <td class="text-primary pr-0 pt-7 text-right align-middle">
                      {{ parseFloat(order.total) | currencyFormat }} €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0"
        >
          <div class="col-md-10">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="font-weight-bold text-muted text-uppercase">
                      PAYMENT TYPE
                    </th>
                    <th class="font-weight-bold text-muted text-uppercase">
                      PAYMENT STATUS
                    </th>
                    <th class="font-weight-bold text-muted text-uppercase">
                      DELIVERY
                    </th>
                    <th
                      class="font-weight-bold text-muted text-uppercase text-right"
                    >
                      TOTAL PAID
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="font-weight-bolder">
                    <td>--</td>
                    <td>{{ order.status }}</td>
                    <td>
                      <span v-if="order.delivery === 'shop_pick_up'"
                        >shop pick up</span
                      >
                      <span v-if="order.delivery === 'courier_service_delivery'"
                        >courier service delivery</span
                      >
                    </td>
                    <td
                      class="text-primary font-size-h3 font-weight-boldest text-right"
                    >
                      {{ parseFloat(order.total) | currencyFormat }} €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
          <div class="col-md-10">
            <div class="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-primary font-weight-bold"
                onclick="window.print();"
              >
                Print Order Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../core/services/api.service'

export default {
  name: 'ViewOrder',
  data() {
    return {
      order: null,
      isOrderLoaded: false,
      company: null,
      isCompanyLoaded: false,
      user: null,
      isUserLoaded: false,
      appPath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    ApiService.get('orders', this.$route.params.id).then(({ data }) => {
      this.order = data
      this.isOrderLoaded = true

      if (this.order.hasOwnProperty('company')) {
        ApiService.get(
          'companies',
          this.order.company.replace('/api/v1/companies/', '')
        ).then(({ data }) => {
          this.company = data
          this.isCompanyLoaded = true

          if (this.order.hasOwnProperty('user')) {
            ApiService.get(
              'users',
              this.order.user.replace('/api/v1/users/', '')
            ).then(({ data }) => {
              this.user = data
              this.isUserLoaded = true
            })
          }
        })
      }
    })
  }
}
</script>
